import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import { Link, graphql } from "gatsby";
import { SEO, SeoObject } from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const BlogPost = ({ data }) => {
  const { tags = [], title, date, slug, excerpt, image } = data.mdx.frontmatter;

  let meta: SeoObject = {
    ...data.mdx.frontmatter,
    description: excerpt || data.mdx.excerpt,
    // image: `${slug}/og-image.jpg`,
    title: `${title} - Pearl Harbor Experience`,
  };

  return (
    <Layout>
      <SEO meta={meta} isBlogPost />

      <div className="max-w-screen-2xl m-auto">
        <GatsbyImage image={getImage(image)} alt={""} />
      </div>
      <div className="max-w-screen-lg grid m-auto">
        <div className="bg-white shadow-lg p-6 my-6 min-w-full w-full prose redbordertop">
          <h1>{title}</h1>
          <div>{date}</div>
        </div>
        <div className="bg-white shadow-lg p-6 my-6 min-w-full w-full prose redbordertop">
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPost;

export const mdxLayoutTemplateQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      tableOfContents
      excerpt(pruneLength: 500)
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, aspectRatio: 1.5, formats: [AUTO, WEBP, AVIF])
          }
        }
        slug
        title
        date(formatString: "DD MMMM YYYY")
        relativeDate: date(fromNow: true)
        isoDate: date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
      }
    }
  }
`;
